import { init, browserTracingIntegration, replayIntegration } from '@sentry/react';

// @ts-ignore
import renderHydrogen from '@shopify/hydrogen/entry-client';

const ClientWrapper = (props) => props.children;
init({
    dsn: 'https://55b4f0c7ac2640ec9138bb884f82d04f@o4504281639682048.ingest.sentry.io/4504281643745280',
    ignoreErrors: ['There was an error while hydrating this Suspense boundary', 'Failed to fetch dynamically '],
    integrations: [
        browserTracingIntegration(),
        replayIntegration({ networkDetailAllowUrls: [window.location.origin] }),
    ],
    tracesSampleRate: 1.0,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    environment: window.location.hostname.includes('local.test')
        ? 'dev'
        : window.location.hostname.includes('metispro.nl') || window.location.hostname.includes('expert.skantrae')
        ? 'production'
        : 'test',
    debug: false,
    enabled: !window.location.hostname.includes('local.test'),
    beforeSend: (event) => {
        if (window.location.hostname.includes('local.test')) return null;
        return event;
    },
});

export default renderHydrogen(ClientWrapper);
